<template>
  <div class="m-error error">
    <SfImage
      src="/assets/error.svg"
      class="error__image"
    />
    <SfHeading
      :level="level"
      :title="title"
      :description="subtitle"
      class="error__message"
    />
    <MErrorActions />
  </div>
</template>

<script>
import SfImage from '@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue';
import SfHeading from '@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue';

import MErrorActions from '~/theme/components/molecules/m-error-actions';

export default {
  name: 'MError',
  components: { SfImage, SfHeading, MErrorActions },
  props: {
    level: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.error {
  margin: auto;
  padding: 0 var(--spacer-sm);
  &__image,
  &__message {
    margin: calc(var(--spacer-xl) * 2) 0;
    text-align: center;
    --heading-border: none;
  }
  &__image {
    display: flex;
    justify-content: center;
  }
}
</style>
