<template>
  <div class="m-error error">
    <div class="error__action">
      <a :href="localizedRoute('/')">
        <SfButton class="sf-button--full-width error__action-button">
          {{ $t("Return to home") }}
        </SfButton>
      </a>
      <SfButton
        class="sf-button--pure sf-button--full-width sf-button--text error__action-button error__action-button--secondary"
        @click="$router.back()"
      >
        {{ $t("Back") }}
      </SfButton>
    </div>
  </div>
</template>

<script>
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue'

export default {
  name: 'MErrorActions',
  components: { SfButton },
  props: {
    level: {
      type: Number,
      default: 1
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.error {
  margin: auto;
  padding: 0 var(--spacer-sm);
  &__action {
    max-width: 24rem;
    margin: var(--spacer-sm) auto;
    @include for-desktop {
      display: flex;
    }
  }
  &__action-button {
    flex: 1;
    &--secondary {
      margin: var(--spacer-xl) 0;
      @include for-desktop {
        margin: 0;
        text-align: right;
      }
    }
  }
}
</style>
