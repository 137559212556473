<template>
  <div class="error" id="error" v-if="isLoaded">
    <template v-if="content">
      <AStatic :content="content" />
      <MErrorActions />
    </template>
    <MError v-else :title="errorTitle" :subtitle="errorSubtitle" />
    <MRecommendedProducts :header="$t('Recommended to you')" slider-id="top" :products="recommendedCarousel" />
    <MRecommendedProducts :header="$t('Bestsellers')" slider-id="bottom" :products="bestSellersCarousel" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import i18n from '@vue-storefront/i18n';
import { Logger } from '@vue-storefront/core/lib/logger';
import AStatic from '~/theme/components/atoms/a-static';
import MError from '~/theme/components/molecules/m-error';
import MErrorActions from '~/theme/components/molecules/m-error-actions';
import MRecommendedProducts from '~/theme/components/molecules/m-recommended-products';
import config from 'config';

export default {
  name: 'Error',
  components: {
    AStatic,
    MError,
    MErrorActions,
    MRecommendedProducts
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoaded: false,
      content: null,
      recommendedCarousel: [],
      bestSellersCarousel: []
    }
  },
  computed: {
    ...mapGetters({
      cartItems: 'cart/getCartItems'
    }),
    errorTitle () {
      return this.$route.name === 'error'
        ? i18n.t('Something went wrong ...')
        : i18n.t("We can't find the page");
    },
    errorSubtitle () {
      return this.$route.name === 'error'
        ? i18n.t("We've noticed Internal Server Error while rendering this request.")
        : i18n.t("Unfortunately we can't find the page you are looking for.");
    },
    errorMetaTitle () {
      return this.$route.name === 'error'
        ? i18n.t('Internal Server Error 500')
        : i18n.t('404 Page Not Found');
    }
  },
  methods: {
    async loadRetailProducts () {
      this.recommendedCarousel = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.errorPageRecommendedId);
      this.bestSellersCarousel = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.errorPageBestSellersId);
    }
  },
  async mounted () {
    try {
      const match = { value: this.$route.name === 'error' ? 'error' : 'not-found' }
      const page = await this.$store.dispatch('cmsPage/single', match)
      this.content = {
        title: page?.title,
        message: page?.content
      }
    } catch (err) {
      Logger.log('Cannot fetch page content from CMS')();
    }
    this.isLoaded = true
    await this.loadRetailProducts();
  },
  async asyncData ({ context }) {
    return new Promise((resolve) => {
      Logger.log('Calling asyncData for Error page ' + new Date())();
      if (context) {
        context.output.cacheTags.add(`error`);
        context.server.response.statusCode = 404
      }

      resolve();
    })
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || this.title || this.errorMetaTitle,
      meta: [
        ...(this.$route.meta.description
          ? [{ vmid: 'description', name: 'description', content: this.$route.meta.description }]
          : []),
        { vmid: 'robots', name: 'robots', content: 'noindex, nofollow' },
        // Identifier for error pages so that cache can skip them. We put them in meta, so it will be very early in the HTML
        { name: 'data-is-error-page' }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
  @import "~@storefront-ui/shared/styles/helpers/breakpoints";

  .error {
    box-sizing: border-box;
    padding: 0 var(--spacer-sm);
    content-visibility: auto;
    contain-intrinsic-size: 1px 4900px;
    @include for-desktop {
      padding: 0 var(--spacer-sm);
      max-width: var(--container-width);
      margin: 0 auto;
    }
  }
  .error-page__carousel {
    width: 100%;
  }
  .section-recommended {
    --section-content-margin: 0;
    --section-margin: 0;
    min-height: 19rem;
    @include for-desktop {
      min-height: 28rem;
    }
    transition: min-height .2s ease-out;
  }
</style>
